<template>
  <Loading :loading="pageinfo.isLoading" />
  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.Books') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <router-link to="/book/list" class="text-gray-400 uppercase text-sm"><small>{{ t('site.Books') }}</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.Books') }}</b></a>
    </div>
  </div>

  <div :key="pageinfo.key">
    <Bookinfo :book="pageinfo.book" />

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-4 p-2">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div class="flex justify-start">
            <!--<button @click="fnCreateArticle" class="px-4 py-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50 mr-2">
              <i class="fa-solid fa-pencil"></i> {{ t('site.book_table_button_write') }}
            </button>-->
            <button class="px-4 py-2 uppercase bg-pink-400 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50" @click="fnAppendChapter">
              <i class="fa-solid fa-folder-plus"></i> {{t('site.AddChapter')}}</button>
          </div>
        <div class="flex justify-end">
          <button @click="goBookList" class="px-4 py-2 mr-2 uppercase bg-gray-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
          </button>
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="goReviews" class="px-4 py-2 mr-2 uppercase bg-blue-600 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-book-open-reader"></i> {{t('site.book_table_button_review')}}
          </button>
          <!--
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="fnStory" class="px-4 py-2 mr-2 uppercase bg-orange-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-pencil"></i> {{t('site.stodymanager')}}
          </button>-->
          <button v-if="pageinfo.book.id !== null && pageinfo.book.id !== undefined && String(pageinfo.book.id).trim() !== ''" @click="fnSetup" class="px-4 py-2 mr-2 uppercase bg-orange-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
            <i class="fa-solid fa-pencil"></i> 작품설정
          </button>
        </div>
      </div>
    </div>

    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white mt-4">
      <div class="block w-full overflow-y-auto h-96 p-4">
        <div
          v-for="(chapter, chapterIndex) in pageinfo.book.chapters"
          :key="`chapter_${chapter.id}`"
          class="mb-4"
        >
          <div
            class="bg-gray-200 rounded-lg p-3 cursor-pointer flex justify-between"
            draggable="true"
            @dragstart="onDragStart($event, 'chapter', chapterIndex)"
            @dragover.prevent
            @drop="onDrop($event, 'chapter', chapterIndex, null)"
          >
            <h4 class="text-lg font-semibold">{{ chapter.title }}</h4>
            <div>
              <button class="text-sm bg-green-500 text-white rounded-md px-2 py-1" @click="fnArticleAppend(chapter)">{{ t('site.ArticleAppend') }}</button>
              <button class="ml-2 text-sm bg-blue-500 text-white rounded-md px-2 py-1" @click="fnChapterEdit(chapter)">{{ t('common.Edit') }}</button>
              <button class="ml-2 text-sm bg-red-500 text-white rounded-md px-2 py-1" @click="fnChapterDelete(chapter, chapterIndex)">{{ t('common.Delete') }}</button>
            </div>
          </div>
          <ul class="pl-6">
            <li
              v-for="(article, articleIndex) in chapter.articles"
              :key="`article_${article.id}`"
              class="mt-2 bg-white p-2 rounded shadow-sm cursor-pointer"
              draggable="true"
              @dragstart="onDragStart($event, 'article', chapterIndex, articleIndex)"
              @dragover.prevent
              @drop="onDrop($event, 'article', chapterIndex, articleIndex)"
            >
              <div class="flex justify-start items-center">
                <a href="#" @click="fnEditArticle(article.id)" class="font-bold">{{ article.title }}</a>
                <span class="pl-4 text-gray-300 text-sm"> {{ countCharacters(article.content) }} chars, {{ article.readCount }} reads, {{ article.likeCount }} likes</span>
              </div>
              
              <div class="w-full flex justify-between">
                <div class="text-xs text-gray-500">
                  {{ (validate.IsNullOrWhiteSpace(article.content) || article.content.length < 30) ? article.content : article.content.substring(0,30) }}
                </div>
                <div>
                  <button class="text-mini bg-red-500 text-white btn-mini" @click="fnArticleRemove(chapter, article, articleIndex)">{{ t('common.Delete') }}</button>
                  <button class="ml-3 text-mini bg-green-500 text-white btn-mini" @click="copyToClipboard(pageinfo.book.description)">{{ t('common.copy') }}</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import { Loading, Bookinfo } from '@/components';
import { onMounted, ref, defineProps, Ref } from 'vue';
import { ArticleRepository,MemberRepository } from '@/repositories';
import { Article, Book, Chapter, Member } from '@/entities';
import { MessageBox,Notification,ValidateHelper } from '@/utility';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const articleRep = new ArticleRepository();
const memberRep = new MemberRepository();
const router = useRouter();
const { t } = useI18n();
const validate = new ValidateHelper();

interface BookConfigProperty {
  bookid?: string | null | undefined;
}

const property = defineProps<BookConfigProperty>();

const pageinfo = ref({
  key: 0,
  isLoading: true,
  userinfo:new Member(),
  book: new Book(),
  totalcount: 0,
  curpage: 1
});

onMounted(async () => {
  await getUser();
  await GetBookInfo();
  pageinfo.value.isLoading = false;
  pageinfo.value.key += 1;
});

const getUser = async () => {
  let rst = await memberRep.GetUser();
  if (rst.check && rst.data !== null) {
    pageinfo.value.userinfo = rst.data;
  }
};

const GetBookInfo = async (): Promise<void> => {
  if (property.bookid) {
    const rst = await articleRep.GetBook(property.bookid);
    if (rst.check) {
      // Book 데이터를 가져온 후 정렬을 수행
      const bookData = rst.data;

      // 챕터를 seq 기준으로 정렬
      bookData.chapters.sort((a: Chapter, b: Chapter) => a.seq - b.seq);

      // 각 챕터 내의 아티클도 seq 기준으로 정렬
      bookData.chapters.forEach((chapter: Chapter) => {
        chapter.articles.sort((a: Article, b: Article) => a.seq - b.seq);
      });

      pageinfo.value.book = bookData;
      pageinfo.value.key += 1;
    }
  }
};

const goArticles = () => {
  router.push(`/book/articles/${pageinfo.value.book.id}`);
};

const goReviews = () => {
  router.push(`/book/${pageinfo.value.book.id}/reviews`);
};

const goBookList = () => {
  router.push('/book/list');
};

const fnStory = () => {
  router.push(`/book/story/${pageinfo.value.book.id}`);
};

const fnEditArticle = (articleId: string): void => {
  router.push(`/book/article/${property.bookid}/${articleId}`);
};

const fnCreateArticle = (): void => {
  router.push(`/book/article/${pageinfo.value.book.id}`);
};

const fnSetup = (): void => {
  router.push(`/book/settings/${pageinfo.value.book.id}`);
};



const formatRegistDate = (date: string): string => {
  return dayjs.utc(date).local().format('YYYY-MM-DD HH:mm');
};


const copyToClipboard = async (text:string) => {
  try {
    await navigator.clipboard.writeText(text);
    MessageBox.Success(t('common.copyComplete'));
  } catch (err) {
    console.error('Failed to copy text to clipboard:', err);
  }
};

interface DraggedItem {
  type: 'chapter' | 'article';
  chapterIndex: number;
  articleIndex: number | null;
}

let draggedItem: DraggedItem | null = null;

const onDragStart = (event: DragEvent, type: 'chapter' | 'article', chapterIndex: number, articleIndex: number | null = null): void => {
  draggedItem = { type, chapterIndex, articleIndex };
  event.dataTransfer?.setData('application/json', JSON.stringify(draggedItem));
  event.dataTransfer!.effectAllowed = 'move';
};

const onDrop = (event: DragEvent, type: 'chapter' | 'article', targetChapterIndex: number, targetArticleIndex: number | null = null): void => {
  if (!draggedItem) return;

  const dragged = JSON.parse(event.dataTransfer!.getData('application/json')) as DraggedItem;
  draggedItem = null;

  if (dragged.type === 'chapter' && type === 'chapter') {
    const [movedChapter] = pageinfo.value.book.chapters.splice(dragged.chapterIndex, 1);
    pageinfo.value.book.chapters.splice(targetChapterIndex, 0, movedChapter);
  } else if (dragged.type === 'article') {
    const sourceArticles = pageinfo.value.book.chapters[dragged.chapterIndex].articles;
    const targetArticles = pageinfo.value.book.chapters[targetChapterIndex].articles;

    // 아티클을 같은 챕터 내에서 이동할 때
    if (dragged.chapterIndex === targetChapterIndex) {
      const [movedArticle] = sourceArticles.splice(dragged.articleIndex!, 1);
      if (targetArticleIndex !== null) {
        targetArticles.splice(targetArticleIndex, 0, movedArticle);
      } else {
        // 아티클을 챕터로 옮길 때 챕터의 마지막으로 이동
        targetArticles.push(movedArticle);
      }
    } 
    // 아티클을 다른 챕터로 이동할 때
    else {
      const [movedArticle] = sourceArticles.splice(dragged.articleIndex!, 1);
      if (targetArticleIndex !== null) {
        targetArticles.splice(targetArticleIndex, 0, movedArticle);
      } else {
        // 챕터의 마지막에 아티클 추가
        targetArticles.push(movedArticle);
      }
    }
  }

  onSaveSeq();
};

const onSaveSeq = async () => {
  let json = {
    bookid:'',
    chapters:[] as any[],
    articles:[] as any[]
  };

  let index = 0;

  if (pageinfo.value.book.chapters !== null && pageinfo.value.book.chapters !== undefined && pageinfo.value.book.chapters.length > 0) {
    json.bookid = pageinfo.value.book.id;
    for(let i = 0; i < pageinfo.value.book.chapters.length; i++) {
      json.chapters.push({ id : pageinfo.value.book.chapters[i].id, seq : i });
      if (pageinfo.value.book.chapters[i].articles !== null && pageinfo.value.book.chapters[i].articles !== undefined && pageinfo.value.book.chapters[i].articles.length > 0) {
        for(let j = 0; j < pageinfo.value.book.chapters[i].articles.length; j++) {
          json.articles.push({ id : pageinfo.value.book.chapters[i].articles[j].id, chapterId : pageinfo.value.book.chapters[i].id, seq : index++ });
        }
      }
    }
  }

  let rst = await articleRep.PutSeqUpdate(json);
  if (!rst.check) {
    Notification.Alert(rst.message);
  }

};

const fnAppendChapter = () => {
    MessageBox.Console(t('site.requiredChapterName'), async (chapterName:string) => {
      let chapter = new Chapter();
      chapter.bookId = pageinfo.value.book.id;
      chapter.seq = getMaxSeq();
      chapter.title = chapterName;
      let rst = await articleRep.PostChapterRegist(pageinfo.value.userinfo.id, chapter);
      if (rst.check) {
        pageinfo.value.book.chapters.push(rst.data);
        pageinfo.value.key += 1;
        MessageBox.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const getMaxSeq = ():number => {
    if (pageinfo.value.book.chapters !== null && pageinfo.value.book.chapters !== undefined && pageinfo.value.book.chapters.length > 0) {
      return Math.max(...pageinfo.value.book.chapters.map(x => x.seq)) + 1;
    } else {
      return 1;
    }
  };

  const fnArticleAppend = (chapter:Chapter) => {
    MessageBox.Console(t('site.requiredArticleTitle'), async (subject:string) => {
      let article = new Article();
      article.chapterId = chapter.id;
      article.title = subject;
      article.content = "";
      let rst = await articleRep.PostArticleRegist(article);
      if (rst.check) {
        chapter.articles.push(rst.data);
        pageinfo.value.key += 1;
        Notification.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    });
    
  };

  const fnArticleRemove = (chapter:Chapter, article:Article, index:number) => {
    MessageBox.Confirm(t("system.RemoveConfirm"), async () => {
      let rst = await articleRep.DeleteArticle(article.id);
      if (rst.check) {
        chapter.articles.splice(index, 1);
        pageinfo.value.key += 1;
        Notification.Success(t('system.Deleted'));
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const fnChapterEdit = (chapter:Chapter) => {
    MessageBox.Console(t('site.requiredChapterName'), async (chapterName:string) => {
      chapter.title = chapterName;
      let rst = await articleRep.PutChapterRegist(pageinfo.value.userinfo.id, chapter);
      if (rst.check) {
        pageinfo.value.key += 1;
        Notification.Success(t('system.Saved'));
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const fnChapterDelete = (chapter:Chapter, index:number) => {
    MessageBox.Confirm(t("system.RemoveConfirm"), async () => {
      let rst = await articleRep.DeleteChapter(chapter.id);
      if (rst.check) {
        pageinfo.value.book.chapters.splice(index, 1);
        pageinfo.value.key += 1;
        Notification.Success(t('system.Deleted'));
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };


  const countCharacters = (str:string) => {
    let cleanText = str.replace(/[^a-zA-Z0-9가-힣]/g, '').replace(/\s+/g, '');
    return Array.from(cleanText).length;
  }
</script>
