<template>
  <Loading :loading="pageinfo.isLoading" />

  <div class="w-full flex justify-between mb-1">
    <div class="ml-2">
      <h3 class="text-2xl subcolor uppercase"><b>{{ t('site.stodymanager') }}</b></h3>
    </div>
    <div class="text-sm mr-2">
      <router-link to="/main" class="text-gray-400 uppercase text-sm"><small>HOME</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <router-link to="/book/list" class="text-gray-400 uppercase text-sm"><small>Books</small></router-link>
      <span class="px-2 text-gray-300">&gt;</span>
      <a href="#" class="text-gray-500 uppercase text-md"><b>{{ t('site.stodymanager') }}</b></a>
    </div>
  </div>

  <div class="min-h-screen flex flex-col p-8 bg-gray-100" :key="pageinfo.key">
    <!-- 작품 정보 섹션 -->
    <div class="flex mb-8">
      <!-- 표지 -->
        <div class="shadow-lg mr-4 bg-white p-2">
          <img :src="pageinfo.book.cover" class="bookcover-size" />
        </div>
      <!-- 작품 정보 -->
      <div class="flex flex-col justify-center ml-4 w-full break-words">
        <h1 class="text-4xl font-bold text-gray-800 mb-4">{{ pageinfo.book.title }}</h1>
        <p class="text-lg text-gray-600">{{ pageinfo.book.description }}</p>
      </div>
    </div>

    <!-- 버튼 섹션 -->
    <div class="flex justify-between items-center mb-6">
      <button @click="goBookList" class="px-4 py-2 mr-2 uppercase bg-gray-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
        <i class="fa-solid fa-folder"></i> {{ t('common.List') }}
      </button>
      <div class="space-x-4">
        <button @click="fnRegist" class="px-4 py-2 mr-2 uppercase mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-opacity-50">
          <i class="fa-solid fa-floppy-disk"></i> {{ t('common.Regist') }}
        </button>
      </div>
    </div>

    <!-- 구분선 -->
    <hr class="border-gray-300 mb-6" />

    <ul class="flex flex-col space-y-4 flex-grow overflow-y-auto">
      <li
        v-for="(memo, index) in pageinfo.memos"
        :key="index"
        class="bg-white p-4 rounded-md shadow-md flex items-center draggable-item mt-1 mb-1"
      >
        <div class="w-full flex-grow">
          <ul>
            <button @click="fnEdit(memo)">{{ memo.title }}</button>
          </ul>
        </div>
        <button class="icon-btn ml-1" @click="removeMemo(index, memo.id)"><i class="fa-solid fa-trash text-gray-400 text-xl focus:outline-none"></i></button>
      </li>
    </ul>


  </div>

  <div class="fixed inset-0 bg-opacity-50 flex items-center justify-center z-50 popup-bg" v-if="pageinfo.showPopup">
    <!-- 팝업 박스 -->
    <div class="bg-white p-6 rounded-lg shadow-lg max-w-lg" style="width:80%;height:80%;">
      <!-- 팝업 제목 -->
      <h2 class="text-2xl font-bold text-gray-800 mb-4">{{ t('site.stodymanager') }} {{ t('common.ButtonAdd') }}</h2>

      <!-- 제목 입력 필드 -->
      <div class="mb-3">
        <input type="text" id="popupTitle" v-model="pageinfo.popupData.title" class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none" />
      </div>

      <!-- 본문 입력 필드 -->
      <div class="mb-3">
        <WebEditor :content="pageinfo.popupData.content" :multiupload="false" :key="`memo_${pageinfo.key}`" :callback="handleUpdate" />
      </div>

      <!-- 버튼 섹션 -->
      <div class="flex justify-end space-x-4">
        <!-- 취소 버튼 -->
        <button @click="closePopup" class="px-4 py-2 bg-gray-500 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-gray-300">
          {{ t('common.Cancel') }}
        </button>
        <!-- 저장 버튼 -->
        <button @click="savePopupData" class="px-4 py-2 mainbg text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500">
          {{ t('common.Save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { onMounted, ref,defineProps } from 'vue';
  import { Loading } from '@/components';
  import { Book,Memo } from '@/entities';
  import { WebEditor } from '@/components/Editor';
  import { MessageBox, ValidateHelper, Notification } from '@/utility';
  import { useI18n } from 'vue-i18n';
  import { ArticleRepository,MemberRepository } from '@/repositories';
  import { useRoute,useRouter } from 'vue-router';

  const validate = new ValidateHelper();
  const { t, locale } = useI18n();
  const articleRep = new ArticleRepository();
  const memberRep = new MemberRepository();
  const route = useRoute();
  const router = useRouter();

  interface bookConfigProperty {
    bookid?:string|null|undefined
  }

  const property = defineProps<bookConfigProperty>();

  var pageinfo = ref({
    key:1,
    isLoading:true,
    book:new Book(),
    memos:[] as Memo[],
    curpage:1,
    showPopup:false,
    isAppend:true,
    popupData:new Memo()
  });

  onMounted(async () => {
    await GetBookInfo();
    await GetMemos();
    clearForm();
    pageinfo.value.isLoading = false;
    pageinfo.value.key += 1;
  });

  const GetBookInfo = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetBook(property.bookid);
      if (rst.check) {
        pageinfo.value.book = rst.data;
        pageinfo.value.key += 1;
        
      }
    }
  };

  const GetMemos = async () => {
    if (property.bookid !== null && property.bookid !== undefined && property.bookid.trim() !== '') {
      let rst = await articleRep.GetMemos(property.bookid, { curPage : pageinfo.value.curpage, pageSize : 10 });
      if (rst.check) {
        pageinfo.value.memos = rst.data;
        pageinfo.value.key += 1;
      }
    }
  };

  const removeMemo = (index:number, memoid:string) => {
    MessageBox.Confirm(t('system.RemoveConfirm'), async () => {
      let rst = await articleRep.DeleteMemo(memoid);
      if (rst.check) {
        Notification.Success(t('system.Deleted'));
        pageinfo.value.memos.splice(index, 1);
      } else {
        MessageBox.Alert(rst.message);
      }
    });
  };

  const goBookList = () => {
    router.push(`/book/settings/${pageinfo.value.book.id}`);
  };

  const fnRegist = async () => {
    pageinfo.value.showPopup = true;
  };

  const closePopup = () => {
    clearForm();
    pageinfo.value.showPopup = false;
  };

  const savePopupData = async () => {
    let rst = await articleRep.PostMemoRegist(pageinfo.value.popupData);
    if (rst.check) {
      if (pageinfo.value.isAppend) {
        pageinfo.value.memos.push(rst.data);
      }
      clearForm();
      Notification.Success(t('system.Saved'));
      pageinfo.value.showPopup = false;
    } else {
      MessageBox.Alert(rst.message);
    }
  };

  const clearForm = () => {
    pageinfo.value.popupData = new Memo();
    pageinfo.value.popupData.bookId = pageinfo.value.book.id;
    pageinfo.value.isAppend = true;
  };

  const handleUpdate = (content:string) => {
    pageinfo.value.popupData.content = content;
  };

  const fnEdit = (target:Memo) => {
    pageinfo.value.popupData = target;
    pageinfo.value.showPopup = true;
    pageinfo.value.isAppend = false;
  };
</script>

<!-- Tailwind CSS 활용 -->
<style scoped>
.textarea {
  @apply border border-gray-300 p-2 rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300;
  outline: none;
}

.icon-btn {
  @apply text-gray-600 hover:text-red-500 transition duration-300;
  outline: none;
}

.draggable-item {
  @apply cursor-move;
}

.draggable-item.dragging {
  @apply opacity-50;
}
</style>
