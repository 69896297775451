import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { createI18n } from 'vue-i18n';
import { TranslationRepository } from './repositories';
import signalr from './utility/signalr';
import 'vue-toastification/dist/index.css';
import Toast from 'vue-toastification';

const i18n = createI18n({
  legacy: false,
  locale: 'en', // 기본 언어 설정
  messages: {}, // 초기 메시지는 비어 있습니다.
});

let translationRep = new TranslationRepository();

async function loadLocaleMessages(locale: string) {
  try {
    const response = await translationRep.GetTranslations(locale);
    i18n.global.setLocaleMessage(locale, response.data);
  } catch (error) {
    console.error(`Failed to load ${locale} locale messages:`, error);
  }
}

async function setupI18n() {
  const response = await translationRep.GetLanguages();
  if (response !== null && response !== undefined && response.check) {
    for (let i = 0; i < response.data.length; i++) {
      await loadLocaleMessages(response.data[i].languageCode);
    }
  }
}

const app = createApp(App);

setupI18n();

declare global {
  interface Window {
    Loading: (state: boolean) => void;
  }
}

function LoadingClose() {
  if (typeof window.Loading === 'function') {
    window.Loading(false);
  } else {
    console.error('Loading function is not defined');
  }
}

router.beforeEach(async (to, from, next) => {
  if (!i18n.global.availableLocales.length) {
    await setupI18n();
  }
  next();
});

app.use(store);
app.use(router);
app.use(i18n);
app.use(Toast);
app.config.globalProperties.signalr = signalr;
app.config.globalProperties.LoadingClose = LoadingClose;

app.mount('#app');
