<template>
  <Loading :loading="!pagestatus.isReady" />
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-4/12 px-4">
        <div
          class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
        >
          <div class="rounded-t mb-0 px-6 py-6">
            <div class="text-center mb-3">
              <h6 class="text-blueGray-500 text-sm font-bold">
                Sign in with
              </h6>
            </div>
            <div class="btn-wrapper text-center">
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('GitHub')"
              >
                <img alt="..." class="w-5 mr-1" src="/img/github.svg" />
                Github
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('Google')"
              >
                <img alt="..." class="w-5 mr-1" src="/img/google.svg" />
                Google
              </button>
              <button
                class="bg-white active:bg-blueGray-50 text-blueGray-700 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                type="button" @click="fnExternalLogin('Kakao')"
              >
                <img alt="..." class="w-5 mr-1" src="/img/kakao.svg" />
                Kakao
              </button>
            </div>
            <hr class="mt-6 border-b-1 border-blueGray-300" />
          </div>
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <div class="text-blueGray-400 text-center mb-3 font-bold">
              <small>Or sign in with credentials</small>
            </div>
            <form>
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email" v-model="pageinfo.username"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                />
              </div>

              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Password
                </label>
                <input
                  type="password" v-model="pageinfo.password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                />
              </div>
              <!--<div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckLogin"
                    type="checkbox" v-model="pageinfo.rememberLogin"
                    class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                  />
                  <span class="ml-2 text-sm font-semibold text-blueGray-600">
                    Remember me
                  </span>
                </label>
              </div>-->

              <div class="text-center mt-6">
                <button
                  class="subbg text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button" @click="fnSignin()"
                >
                  Sign In
                </button>
              </div>
            </form>
          </div>
        </div>
        <div class="flex flex-wrap mt-6 relative">
          <div class="w-1/2">
            <router-link to="/passwordreset" class="text-blueGray-700">
              <small>Forgot password?</small>
            </router-link>
          </div>
          <div class="w-1/2 text-right">
            <router-link to="/regist" class="text-blueGray-700">
              <small>Create new account</small>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import config from '@/config';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { LoginInputModel } from '@/models';
import { AuthService } from '@/services';
import { MessageBox } from '@/utility';
import { Loading } from '@/components';

const router = useRouter();
const auth = new AuthService();

var pageinfo = ref<LoginInputModel>(new LoginInputModel());
var pagestatus = ref({
  isReady : false
})

onMounted(async () => {
  let user:any = await auth.getUser();
  if (user !== null && user !== undefined && user.access_token !== null && user.access_token !== undefined && String(user.access_token).trim() !== '') {
    router.push('/main');
  }
  pageinfo.value.username = "";
  pageinfo.value.password = "";
  pageinfo.value.rememberLogin = false;
  pagestatus.value.isReady = true;
});

const fnSignin = async () => {
  if (pageinfo.value.username === "") {
    MessageBox.Alert('Email 주소를 입력하세요.');
  } else if (pageinfo.value.password === "") {
    MessageBox.Alert('Password를 입력하세요.');
  } else {
    pagestatus.value.isReady = false;
    let rst = await auth.apiLogin(pageinfo.value.username, pageinfo.value.password);
    if (rst.check) {
      //router.push('/main');
      window.location.href = "/main";
    } else {
      pagestatus.value.isReady = true;
      MessageBox.Alert(rst.message);
    }
  }
}

const fnExternalLogin = async (provider:string) => {
  await auth.ExternalLogin(provider);
}
</script>